<template>
  <modal-card :title="`Manage logs`">
    <logs-table v-bind="$props" class="logs-modal-wpapper" />
  </modal-card>
</template>

<script>
export default {
  name: "LogsModal",
  components: {
    "logs-table": () => import("@shared/logs/_logsTable")
  },
  props: {
    userId: {
      type: String,
      default: ""
    },
    siteId: {
      type: String,
      default: ""
    },
    taskId: {
      type: String,
      default: ""
    },
    event: {
      type: String,
      default: ""
    },
    filterable: {
      type: Boolean,
      required: false,
      default: true
    },
    adminContext: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>
